.container {
  display: flex;
  flex-flow: row wrap;
  max-width: 1120px;
  padding: 20px;
  justify-content: center;
  text-align: left;
  margin: auto;
  width: calc(100% - 40px);
  align-self: center;
}

.verticalContainer {
  display: flex;
  flex-flow: column nowrap;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  max-width: 500px;
  width: 100%;
}

.column iframe {
  height: 300px;
}

.right {
  padding-left: 30px;
}

.form {
  display: flex;
  flex-flow: column nowrap;
}

.textarea,
.input {
  height: 40px;
  margin-bottom: 5px;
  padding: 0 10px;
}

.textarea {
  height: 200px;
  margin-bottom: 20px;
  padding: 10px;
}

.list {
  padding-left: 0;
  margin-top: 0;
}

@media (max-width: 767.98px) {
  .column.right {
    padding-left: 0px;
  }

  .list {
    list-style: none;
  }
}

.formIframe {
  width: 100%;
  height: 100%;
  min-height: 1000px;
}
