.container {
  display: flex;
  padding: 20px;
  max-width: 1120px;
  margin: auto;
  flex-flow: column;
  min-height: 40vh;
  width: calc(100% - 40px);
  align-self: center;
}

.container p {
  text-align: start;
  margin-bottom: 0;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 50px 0;
}

.buttons > * {
  margin-left: 5px;
  margin-bottom: 10px;
}

.logoInText {
  margin-top: 50px;
  max-width: 400px;
  align-self: center;
}
