.container {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 20px;
  max-width: 300px;
  text-align: left;
}

.container p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 5px 0;
}

.image {
  width: 240px;
}
