.container {
  display: flex;
  max-width: 1120px;
  margin: auto;
  padding: 116px 20px 100px 20px;
  flex-flow: row wrap;
  align-self: center;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  max-width: 500px;
}

.left {
  align-items: flex-start;
  padding-right: 20px;
}

.list {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  list-style: circle;
  padding-left: 20px;
  text-align: left;
}

.right {
  align-items: flex-start;
}

.container h2,
.container h4,
.container p {
  margin-bottom: 0;
}

.container h4 {
  margin-top: 0;
}

.title {
  margin: 0;
  opacity: 0.6;
}

.image {
  max-height: 400px;
  max-width: inherit;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 767.98px) {
  .container {
    padding: 60px 20px;
  }
}
