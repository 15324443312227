.container {
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  max-width: 1120px;
  padding: 20px;
  margin: auto;
  align-self: center;
  flex-grow: 1;
}
