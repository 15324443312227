.button {
  display: flex;
  height: 50px;
  width: 250px;
  border-radius: 50px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  background-color: rgba(206, 150, 223, 1);
  border-color: rgba(206, 150, 223, 1);
  color: rgb(0, 0, 0);
}

.button:hover {
  opacity: 0.7;
}

.button:disabled {
  background-color: rgba(194, 188, 188, 1);
}
