.container {
  background: rgb(52, 58, 64);
  display: flex;
  justify-content: space-between;
  color: rgb(255, 255, 255);
  padding: 50px;
  z-index: 0;
  flex-flow: row wrap;
  text-align: left;
  flex-grow: 1;
}

.column {
  display: flex;
  flex-flow: column nowrap;
}

.container a {
  color: rgb(255, 255, 255);
}

.container p {
  margin-bottom: 0px;
}
