.container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 96px;
}

.image {
  height: calc(100vh - 96px);
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 96px;
}

.imageSmall {
  height: calc(60vh - 96px);
}

.largeText {
  color: rgb(255, 255, 255);
  margin-top: 20vh;
  z-index: 1;
}

.image .scrollButton {
  z-index: 1;
  margin-bottom: 20px;
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
}

.titleAndNav {
  display: flex;
  justify-content: space-between;
  height: 96px;
  padding: 0 20px;
  max-width: 1120px;
  margin: auto;
}

.titleAndNavContainer {
  position: fixed;
  z-index: 2;
  width: 100vw;
  background: rgb(255, 255, 255);
}

.titleAndLogo {
  display: flex;
  align-items: center;
}

.titleAndLogo img {
  width: 96px;
  height: 96px;
}

.title {
  display: flex;
  flex-flow: column nowrap;
}

.title h3 {
  text-align: left;
  margin-bottom: 10px;
}

.title h5 {
  margin-top: 0;
}

.navigation {
  display: flex;
}

.navigation_item {
  padding: 30px 10px;
  line-height: 2;
}

.navigation_item__active {
  color: rgb(206, 150, 223);
}

.smallButton {
  width: 50px;
  background-color: rgb(255, 255, 255);
}

.navigationIconMobile {
  display: none;
  cursor: pointer;
  margin: auto 0;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 8px 0;
  transition: 0.4s;
}

.navigationIconToggled .bar1 {
  transform: rotate(-45deg) translate(-9px, 6px);
}

.navigationIconToggled .bar2 {
  opacity: 0;
}

.navigationIconToggled .bar3 {
  transform: rotate(45deg) translate(-8px, -8px);
}

.navigationOverlayMobile {
  display: none;
}

/* @media (max-width: 575.98px) {
} */

@media (max-width: 767.98px) {
  .container {
    min-height: 60px;
  }
  .navigation {
    display: none;
  }

  .titleAndNav {
    height: 60px;
    padding: 0 10px;
  }

  .titleAndLogo img {
    width: 60px;
    height: 60px;
  }

  .title h3 {
    margin-top: 20px;
  }

  .title .subHeader {
    display: none;
  }

  .navigationIconMobile {
    display: inline-block;
  }

  .image {
    margin-top: 60px;
    height: calc(100vh - 60px);
  }

  .imageSmall {
    height: calc(50vh - 60px);
  }

  .navigationOverlayMobile {
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    left: 100vw;
    top: 0;
    height: calc(100vh - 60px);
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .navigationMenuToggled {
    left: 0;
  }

  .navigationMenuMobile {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    background-color: rgb(0, 0, 0);
  }

  .navigation_item_mobile {
    height: 60px;
    width: 200px;
    color: rgb(255, 255, 255);
    line-height: 4;
  }

  .navigation_item_mobile.navigation_item__active {
    background: rgba(255, 255, 255, 0.1);
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

@media (min-width: 768px) {
  .largeText {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .largeText {
    font-size: 3rem;
  }
}
