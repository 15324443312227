.container {
  display: flex;
  margin: auto;
  padding: 20px;
  max-width: calc(100vw - 40px);
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  margin-bottom: 100px;
}
