:root {
  --baseFontSize: 16;
  --baseFontSizeMobile: 14;
  --baseLineHeight: calc(20 / var(--baseFontSize));
  --remRatio: calc(1rem / var(--baseFontSize));
}

html {
  font-size: calc(var(--baseFontSize) * 1px);
  line-height: var(--baseLineHeight);
}

body,
:local(.body) {
  font-weight: 500;
  font-size: 1rem; /* 14px */
  font-stretch: normal;
  line-height: var(--baseLineHeight);
  -webkit-font-smoothing: antialiased;
}

html,
button,
input,
select,
textarea,
small,
body,
.body-font {
  font-family: "PT Sans", sans-serif;
}

.redText {
  color: rgba(206, 150, 223, 1);
}

a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  html {
    font-size: calc(var(--baseFontSizeMobile) * 1px);
    line-height: var(--baseLineHeight);
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}
